import React from "react"
import { Link } from "gatsby"

export default function Home() {
  return (
    <div className="page">
      <section className="home-hero">
        <h1>Deep Dive on Design Tokens</h1>
        
        <Link to="/slides" className="button primary home-hero-button">
          Introduction Slides
        </Link>
      </section>
      
      <section className="home-section">
        <p>In this workshop we will create a design token package along with demo applications in Android, iOS, Web, and Flutter. This is a live-coding workshop, but you don't need to be writing code as we go. The coding part is a tool to explain concepts about design tokens. Also, all of the steps and content that will be covered in this workshop are available online on this site at <Link to="/">deep-dive-on-design-tokens.com</Link>. Each step and the final result of this workshop are available in the <a href="https://github.com/dbanksdesign/clarity-2020-design-token-workshop">Github repository</a> in branches.</p>
        

        <p>If you do want to code-along in the workshop, you will need a few things set up first:</p>
        
        <ul>
          <li>Git</li>
          <li>A text editor or IDE. I will be using VSCode for most of it (and switching to Xcode and Android studio when needed)</li>
          <li><a href="https://nodejs.org/en/download/">Node</a> and npm</li>
          <li><em>optional</em> <a href="https://developer.android.com/studio/">Android Studio</a> and an Android simulator if you want to build the Android demo</li>
          <li><em>optional</em> <a href="https://apps.apple.com/us/app/xcode/id497799835">Xcode</a> 11 or greater if you want to build the iOS demo</li>
        </ul>
      </section>
      
      <section className="home-section">
        <h3>Recording from Clarity 2020</h3>
        <video playsInline="true" controls>
          <source src="https://clarity2020-multiplatform.s3-us-west-1.amazonaws.com/_2020_9_2_rec-lw-us-14_2132824_d1ed5ec08cdf752d29717512ec92477a_recording.mp4" type="video/mp4" />
        </video>
      </section>
    </div>
  )
}
